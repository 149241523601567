import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import ChatPage from './pages/ChatPage/ChatPage';
import CustomPage from './pages/CustomPage/CustomPage';
import GraficPage from './pages/GraficPage/GraficPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import PcmPage from './pages/PcmPage/PcmPage';
import AnaliseDadosPage from './pages/AnaliseDados/AnaliseDados';
import AnaliseBdPage from './pages/AnaliseBd/AnaliseBd';
import AnaliseSegurancaPage from './pages/AnaliseSeguranca/AnaliseSeguranca';
import AnaliseFornecedorPage from './pages/AnaliseFornecedor/AnaliseFornecedor';
import AnalisePreditivaPage from './pages/AnalisePreditiva/AnalisePreditiva';
import AnaliseLeitosPage from './pages/AnaliseLeitos/AnaliseLeitos';
import AnaliseSuprimentosPage from './pages/AnaliseSuprimentos/AnaliseSuprimentos';
import AuxiliarEscritorioPage from './pages/AuxiliarEscritorio/AuxiliarEscritorio';
import PlanejadorPcmPage from './pages/PlanejadorPcm/PlanejadorPcm';
import EngenheiroConfiabilidadePage from './pages/EngenheiroConfiabilidade/EngenheiroConfiabilidade';
import EngenheiroProducaoPage from './pages/EngenheiroProducao/EngenheiroProducao';
import TecnicoManutencaoPage from './pages/TecnicoManutencao/TecnicoManutencao';
import EngenheiroManutencaoPage from './pages/EngenheiroManutencao/EngenheiroManutencao';
import AnalistaConfiabilidadePage from './pages/AnalistaConfiabilidade/AnalistaConfiabilidade';
import IanPage from './pages/IanPage/IanPage';
import GestorManutencaoPage from './pages/GestorManutencao/GestorManutencao';
import AnalistaPcmPage from './pages/AnalistaPcm/AnalistaPcm';
import SupervisorManutencaoPage from './pages/SupervisorManutencao/SupervisorManutencao';
import AnaliseIndoorPage from './pages/AnaliseIndoor/AnaliseIndoor';
import DocumentacaoAgentesPage from './pages/DocumentacaoAgentes/DocumentacaoAgentes';
import PcmNovoPage from './pages/PcmNovo/PcmNovo';
import PlanejadorPcmV3 from './pages/PlanejadorPcmV3/PlanejadorPcmV3';
import './App.css';

function PrivateRoute({ children }) {
  const location = useLocation();
  const storedToken = JSON.parse(localStorage.getItem('authToken'));

  if (!storedToken || !storedToken.token || isTokenExpired(storedToken.expiration)) {
    // Redireciona para o login caso não esteja autenticado
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const isTokenExpired = (expiration) => {
  const now = new Date();
  const expirationDate = new Date(expiration);
  return now > expirationDate;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem('authToken'));
    const storedApi = localStorage.getItem('userApi');
    const storedDbid = localStorage.getItem('userDbid');

    if (!storedToken || !storedToken.token || isTokenExpired(storedToken.expiration)) {
      console.warn('Token inválido ou expirado. Redirecionando para o login.');
      navigate('/login');
      return;
    }

    if (storedApi && storedDbid) {
      const searchParams = new URLSearchParams(location.search);

      if (!searchParams.has('api') || !searchParams.has('dbid')) {
        searchParams.set('api', storedApi);
        searchParams.set('dbid', storedDbid);

        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      }
    }
  }, [location, navigate]);

  return (
    <div className="App">
      <Routes>
        {/* Rotas Públicas */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Rotas Protegidas */}
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <ChatPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/custom"
          element={
            <PrivateRoute>
              <CustomPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/grafic"
          element={
            <PrivateRoute>
              <GraficPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pcm"
          element={
            <PrivateRoute>
              <PcmPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-dados"
          element={
            <PrivateRoute>
              <AnaliseDadosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-dados-bd"
          element={
            <PrivateRoute>
              <AnaliseBdPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-seguranca"
          element={
            <PrivateRoute>
              <AnaliseSegurancaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-fornecedores"
          element={
            <PrivateRoute>
              <AnaliseFornecedorPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-preditiva"
          element={
            <PrivateRoute>
              <AnalisePreditivaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-leito"
          element={
            <PrivateRoute>
              <AnaliseLeitosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-suprimento"
          element={
            <PrivateRoute>
              <AnaliseSuprimentosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/ian"
          element={
            <PrivateRoute>
              <IanPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/auxiliar-escritorio"
          element={
            <PrivateRoute>
              <AuxiliarEscritorioPage />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/planejador-pcm"
          element={
            <PrivateRoute>
              <PlanejadorPcmPage />
            </PrivateRoute>
          }
        />
      */}
        <Route
          path="/engenheiro-confiabilidade"
          element={
            <PrivateRoute>
              <EngenheiroConfiabilidadePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/engenheiro-producao"
          element={
            <PrivateRoute>
              <EngenheiroProducaoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/tecnico-manutencao"
          element={
            <PrivateRoute>
              <TecnicoManutencaoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/engenheiro-manutencao"
          element={
            <PrivateRoute>
              <EngenheiroManutencaoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-confiabilidade"
          element={
            <PrivateRoute>
              <AnalistaConfiabilidadePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/gestor-manutencao"
          element={
            <PrivateRoute>
              <GestorManutencaoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analista-pcm"
          element={
            <PrivateRoute>
              <AnalistaPcmPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/supervisor-manutencao"
          element={
            <PrivateRoute>
              <SupervisorManutencaoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-indoor"
          element={
            <PrivateRoute>
              <AnaliseIndoorPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/documentacao"
          element={
            <PrivateRoute>
              <DocumentacaoAgentesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/planejador-pcm"
          element={
            <PrivateRoute>
              <PlanejadorPcmV3 />
            </PrivateRoute>
          }
        />
        <Route
          path="/pcm-novo"
          element={
            <PrivateRoute>
              <PcmNovoPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
