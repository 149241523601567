import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaComments, FaBook, FaCheckCircle, FaRocket, FaClock, FaMoon, FaSun, FaChartLine, FaCog, FaShieldAlt } from 'react-icons/fa';
import styled, { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    transition: background-color 0.3s, color 0.3s;
    line-height: 1.6;
    background-color: ${props => props.isDarkMode ? '#121212' : '#f5f7fa'};
    color: ${props => props.isDarkMode ? '#e0e0e0' : '#333333'};
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const NavbarStyled = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: ${props => props.isDarkMode ? '#1e1e1e' : '#ffffff'};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin-right: 1.5rem;

  a {
    color: ${props => props.isDarkMode ? '#e0e0e0' : '#333333'};
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: color 0.3s;

    &:hover {
      color: #0056b3;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
`;

const ThemeToggle = styled.button`
  background: none;
  border: none;
  color: ${props => props.isDarkMode ? '#e0e0e0' : '#333333'};
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s;

  &:hover {
    color: #0056b3;
  }
`;

const MainContent = styled.main`
  flex: 1;
  padding: 3rem 5%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: ${props => props.isDarkMode ? '#ffffff' : '#333333'};
  text-align: center;
  position: relative;
  font-weight: 600;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #0056b3;
    margin: 0.5rem auto 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
`;

const Card = styled.div`
  background-color: ${props => props.isDarkMode ? '#2c2c2c' : '#ffffff'};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: ${props => props.isDarkMode ? '#ffffff' : '#333333'};
    font-weight: 600;
  }

  ul {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
  }

  li {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
      color: #28a745;
    }
  }
`;

const CTAButton = styled.button`
  background-color: #0056b3;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #003d82;
    transform: scale(1.05);
  }
`;

const FooterStyled = styled.footer`
  background-color: ${props => props.isDarkMode ? '#1e1e1e' : '#f8f9fa'};
  color: ${props => props.isDarkMode ? '#e0e0e0' : '#333333'};
  text-align: center;
  padding: 2rem;
  font-size: 0.9rem;
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #0056b3;
`;

const Navbar = ({ toggleTheme, isDarkMode, sidebar, options }) => {
  return (
    <NavbarStyled isDarkMode={isDarkMode}>
      <NavList>
        {options.map((option, index) => (
          <NavItem key={index} isDarkMode={isDarkMode}>
            <a href={option.link}>
              {option.icon}
              {option.name}
            </a>
          </NavItem>
        ))}
      </NavList>
      <ThemeToggle onClick={toggleTheme} isDarkMode={isDarkMode}>
        {isDarkMode ? <FaSun /> : <FaMoon />}
      </ThemeToggle>
    </NavbarStyled>
  );
};

const Footer = ({ isDarkMode }) => {
  return (
    <FooterStyled isDarkMode={isDarkMode}>
      <p>&copy; 2024 MIAGS - Todos os direitos reservados</p>
    </FooterStyled>
  );
};

const Home = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode');
  };

  const sidebarOptions = [
    { name: 'Início', link: '/', icon: <FaRocket /> },
    { name: 'Chat', link: '/chat', icon: <FaComments /> },
    { name: 'Documentação', link: '/docs', icon: <FaBook /> },
  ];

  const handleNavigateToChat = () => {
    navigate('/chat');
  };

  return (
    <>
      <GlobalStyle isDarkMode={isDarkMode} />
      <PageContainer>
        <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} sidebar="sim" options={sidebarOptions} />

        <MainContent>
          <Section>
            <SectionTitle isDarkMode={isDarkMode}>Sistema MIAGS</SectionTitle>
            <p style={{ textAlign: 'center', fontSize: '1.1rem', maxWidth: '800px', margin: '0 auto 2rem' }}>
              Menu de Inteligência Artificial Generativa de Soluções para a indústria e manutenção PCM.
              Revolucione sua operação com nossa tecnologia de ponta.
            </p>
            <CTAButton onClick={handleNavigateToChat}>
              Solicite uma Demonstração
            </CTAButton>
          </Section>

          <Section>
            <SectionTitle isDarkMode={isDarkMode}>Agentes Especializados</SectionTitle>
            <Grid>
              <Card isDarkMode={isDarkMode}>
                <IconWrapper>
                  <FaRocket />
                </IconWrapper>
                <h3>Agentes de Desenvolvimento</h3>
                <p>Criam e otimizam soluções de software personalizadas para sua indústria.</p>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <IconWrapper>
                  <FaChartLine />
                </IconWrapper>
                <h3>Agentes de Análise de Dados</h3>
                <p>Interpretam grandes volumes de dados industriais para insights valiosos.</p>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <IconWrapper>
                  <FaCog />
                </IconWrapper>
                <h3>Agentes Personalizados</h3>
                <p>Adaptados às necessidades específicas de cada departamento.</p>
              </Card>
            </Grid>
          </Section>

          <Section>
            <SectionTitle isDarkMode={isDarkMode}>Planos de Assinatura</SectionTitle>
            <Grid>
              <Card isDarkMode={isDarkMode}>
                <h3>Plano Basic</h3>
                <ul>
                  <li><FaCheckCircle /> Acesso a 2 agentes especializados</li>
                  <li><FaCheckCircle /> Suporte por e-mail</li>
                  <li><FaCheckCircle /> Atualizações mensais</li>
                </ul>
                <CTAButton onClick={handleNavigateToChat}>
                  Escolher Basic
                </CTAButton>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <h3>Plano Gold</h3>
                <ul>
                  <li><FaCheckCircle /> Acesso a 5 agentes especializados</li>
                  <li><FaCheckCircle /> Suporte prioritário por e-mail e chat</li>
                  <li><FaCheckCircle /> Atualizações semanais</li>
                  <li><FaCheckCircle /> Treinamento básico para a equipe</li>
                </ul>
                <CTAButton onClick={handleNavigateToChat}>
                  Escolher Gold
                </CTAButton>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <h3>Plano Platinum</h3>
                <ul>
                  <li><FaCheckCircle /> Acesso a todos os agentes especializados</li>
                  <li><FaCheckCircle /> Suporte 24/7 por e-mail, chat e telefone</li>
                  <li><FaCheckCircle /> Atualizações em tempo real</li>
                  <li><FaCheckCircle /> Treinamento avançado para a equipe</li>
                  <li><FaCheckCircle /> Personalização de 1 agente</li>
                </ul>
                <CTAButton onClick={handleNavigateToChat}>
                  Escolher Platinum
                </CTAButton>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <h3>Plano Enterprise</h3>
                <ul>
                  <li><FaCheckCircle /> Todos os benefícios do Plano Platinum</li>
                  <li><FaCheckCircle /> Número ilimitado de usuários</li>
                  <li><FaCheckCircle /> Personalização de múltiplos agentes</li>
                  <li><FaCheckCircle /> Integração completa com sistemas existentes</li>
                  <li><FaCheckCircle /> Consultor dedicado</li>
                </ul>
                <CTAButton onClick={handleNavigateToChat}>
                  Escolher Enterprise
                </CTAButton>
              </Card>
            </Grid>
          </Section>

          <Section>
            <SectionTitle isDarkMode={isDarkMode}>Por que escolher o MIAGS?</SectionTitle>
            <Grid>
              <Card isDarkMode={isDarkMode}>
                <IconWrapper>
                  <FaClock />
                </IconWrapper>
                <h3>Otimização de Processos</h3>
                <p>Reduza o tempo de manutenção e aumente a eficiência operacional.</p>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <IconWrapper>
                  <FaChartLine />
                </IconWrapper>
                <h3>Análise Avançada</h3>
                <p>Obtenha insights valiosos com nossa análise de dados industrial.</p>
              </Card>
              <Card isDarkMode={isDarkMode}>
                <IconWrapper>
                  <FaShieldAlt />
                </IconWrapper>
                <h3>Segurança e Conformidade</h3>
                <p>Garanta a proteção dos seus dados e conformidade com as normas do setor.</p>
              </Card>
            </Grid>
          </Section>

          <Section>
            <SectionTitle isDarkMode={isDarkMode}>Pronto para Transformar sua Indústria?</SectionTitle>
            <p style={{ textAlign: 'center', fontSize: '1.1rem', maxWidth: '800px', margin: '0 auto 2rem' }}>
              Junte-se às empresas que já estão aproveitando o poder do MIAGS e leve sua operação ao próximo nível.
            </p>
            <CTAButton onClick={handleNavigateToChat}>
              Agende uma Demonstração Gratuita
            </CTAButton>
          </Section>
        </MainContent>

        <Footer isDarkMode={isDarkMode} />
      </PageContainer>
    </>
  );
};

export default Home;