import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './AnaliseFornecedor.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import agentImage from '../../utils/agent.png';
import userIcon from '../../utils/user_icon.png';

// Componente para o menu de URLs de endpoints
function UrlMenu({ urls, setUrls }) {
  const [newUrl, setNewUrl] = useState('');

  const handleAddUrl = () => {
    if (newUrl.trim()) {
      setUrls((prevUrls) => [...prevUrls, newUrl]);
      setNewUrl('');
    }
  };

  return (
    <div className="url-menu">
      <h3>Endpoints</h3>
      <ul className="url-list">
        {urls.map((url, index) => (
          <li key={index} className="url-item">
            {url}
          </li>
        ))}
      </ul>
      <div className="url-input-container">
        <input
          type="text"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          className="url-input"
          placeholder="Adicionar novo endpoint"
        />
        <button onClick={handleAddUrl} className="url-add-button">
          Adicionar
        </button>
      </div>
    </div>
  );
}

function AnaliseFornecedor() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [darkMode] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [urls, setUrls] = useState([]);

  const getEndpoint = () => 'http://127.0.0.1:5000/analisar-fornecedores'; // Endpoint fixo para o POST

  const formatBotResponse = (data) => {
    if (data.analise) {
      return `Relatório de Análise:\n${data.analise}`;
    } else if (data.final_output) {
      return `Resultado da análise:\n${data.final_output}`;
    } else if (data.resultado_analise) {
      return `Análise detalhada:\n${data.resultado_analise}`;
    } else if (data.file_path) {
      return 'Página React gerada com sucesso!';
    } else {
      return 'Ação concluída, mas nenhum resultado foi retornado.';
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() === '') return;

    const userMessage = { sender: 'user', text: inputValue };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue('');
    setIsTyping(true);

    try {
      const endpoint = getEndpoint();
      const requestData = {
        descricao: inputValue,
        api_urls: urls,
      };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`Erro ao se comunicar com o servidor: ${response.statusText}`);
      }

      const data = await response.json();
      setIsTyping(false);

      const botMessage = { sender: 'bot', text: formatBotResponse(data) };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      const errorMessage = { sender: 'bot', text: 'Erro ao enviar mensagem. Tente novamente mais tarde.' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
      setIsTyping(false);
    }
  };

  return (
    <div className={`page-container ${darkMode ? 'dark-mode' : ''}`}>
      <Navbar />
      <div className="content-wrap">
        <main className="chat-container">
          <AgentMenu />
          <div className="chat-window-container">
            <div className="chat-window">
              <div className="chat-messages">
                {messages.map((msg, index) => (
                  <div key={index} className={`message ${msg.sender}`}>
                    <div className="message-icon">
                      {msg.sender === 'user' ? (
                        <img src={userIcon} alt="Usuário" className="user-icon" />
                      ) : (
                        <img src={agentImage} alt="Agente" className="agent-icon" />
                      )}
                    </div>
                    <div className="message-content">
                      <p>{msg.text}</p>
                    </div>
                  </div>
                ))}
                {isTyping && (
                  <div className="message bot">
                    <div className="message-icon">
                      <img src={agentImage} alt="Agente" className="agent-icon" />
                    </div>
                    <div className="message-content">
                      <p className="typing">Digitando<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>
                    </div>
                  </div>
                )}
              </div>
              <div className="chat-input">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="input-field"
                  placeholder="Digite sua mensagem ou descrição"
                />
                <button className="send-button" onClick={handleSendMessage}>
                  Enviar
                </button>
              </div>
            </div>
            <UrlMenu urls={urls} setUrls={setUrls} />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default AnaliseFornecedor;
