import React, { useState } from 'react';

const CustomPage = () => {
  const [input, setInput] = useState('');

  const handleButtonClick = (value) => {
    setInput(input + value);
  };

  const calculate = () => {
    try {
      // Avalia a expressão matematicamente
      const result = eval(input);
      setInput(result.toString());
    } catch (error) {
      setInput('Error');
    }
  };

  const clearInput = () => {
    setInput('');
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'black',
      color: 'gold',
      fontFamily: 'Arial, sans-serif',
    },
    display: {
      width: '100%',
      padding: '20px',
      fontSize: '2em',
      textAlign: 'right',
      backgroundColor: 'white',
      borderRadius: '10px',
      marginBottom: '10px',
      boxShadow: '0 0 10px gold',
    },
    button: {
      width: '70px',
      height: '70px',
      margin: '5px',
      fontSize: '1.5em',
      backgroundColor: 'gold',
      color: 'black',
      border: 'none',
      borderRadius: '10px',
      cursor: 'pointer',
      transition: '0.3s',
      boxShadow: '0 0 5px black',
    },
    buttonHover: {
      backgroundColor: 'black',
      color: 'gold',
    },
    buttonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <input
        style={styles.display}
        type="text"
        value={input}
        disabled
      />
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={() => handleButtonClick('7')}>7</button>
        <button style={styles.button} onClick={() => handleButtonClick('8')}>8</button>
        <button style={styles.button} onClick={() => handleButtonClick('9')}>9</button>
        <button style={styles.button} onClick={() => handleButtonClick('/')}>/</button>
        <button style={styles.button} onClick={() => handleButtonClick('4')}>4</button>
        <button style={styles.button} onClick={() => handleButtonClick('5')}>5</button>
        <button style={styles.button} onClick={() => handleButtonClick('6')}>6</button>
        <button style={styles.button} onClick={() => handleButtonClick('*')}>*</button>
        <button style={styles.button} onClick={() => handleButtonClick('1')}>1</button>
        <button style={styles.button} onClick={() => handleButtonClick('2')}>2</button>
        <button style={styles.button} onClick={() => handleButtonClick('3')}>3</button>
        <button style={styles.button} onClick={() => handleButtonClick('-')}>-</button>
        <button style={styles.button} onClick={() => clearInput()}>C</button>
        <button style={styles.button} onClick={() => handleButtonClick('0')}>0</button>
        <button style={styles.button} onClick={() => calculate()}>=</button>
        <button style={styles.button} onClick={() => handleButtonClick('+')}>+</button>
      </div>
    </div>
  );
};

export default CustomPage;