import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const DashboardPage = () => {
  // Estado para alternar o tema escuro
  const [darkMode, setDarkMode] = useState(false);

  // Estilos embutidos para a página e seções
  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: darkMode ? '#121212' : '#f4f7fc',
  };

  const contentWrapStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    marginTop: '60px',
  };

  const chartGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',
    marginBottom: '40px',
  };

  const chartItemStyle = {
    background: darkMode ? '#1f1f1f' : '#fff',
    color: darkMode ? '#ddd' : '#333',
    borderRadius: '20px',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  };

  const chartFullWidthStyle = {
    background: darkMode ? '#1f1f1f' : '#fff',
    color: darkMode ? '#ddd' : '#333',
    borderRadius: '20px',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '40px',
    fontSize: '28px',
    fontWeight: 'bold',
    color: darkMode ? '#ddd' : '#333',
  };

  return (
    <div style={pageStyle}>
      {/* Navbar */}
      <Navbar />

      {/* Conteúdo central */}
      <div style={contentWrapStyle}>
        <h1 style={titleStyle}>Dashboard de Análises</h1>

        {/* Divisão em duas colunas */}
        <div style={chartGridStyle}>
          {/* Seção para o primeiro gráfico */}
          <div style={chartItemStyle}>
            <h2>Gráfico 1</h2>
            {/* Coloque o componente do gráfico aqui */}
          </div>

          {/* Seção para o segundo gráfico */}
          <div style={chartItemStyle}>
            <h2>Gráfico 2</h2>
            {/* Coloque o componente do gráfico aqui */}
          </div>
        </div>

        {/* Seção para gráfico de largura total */}
        <div style={chartFullWidthStyle}>
          <h2>Gráfico de Largura Completa</h2>
          {/* Coloque o componente do gráfico de largura total aqui */}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DashboardPage;
