import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { FaLaptopCode, FaChartBar, FaCogs, FaChevronLeft, FaBrain, FaChevronRight, FaNetworkWired, FaUsers, FaExclamationTriangle, FaBriefcase, FaTimes, FaCode, FaReact, FaNodeJs, FaServer, FaGitAlt, FaPython, FaChartLine, FaRobot, FaDatabase, FaWarehouse, FaExchangeAlt, FaProjectDiagram, FaTools, FaChartPie, FaClipboardCheck, FaClock, FaMoneyBillWave, FaClipboardList, FaIndustry } from 'react-icons/fa';
import defaultAgentImage from '../../utils/agent.png';
import carouselImage1 from '../../utils/carrosel/a1.jpg';
import carouselImage2 from '../../utils/carrosel/a2.jpg';
import carouselImage3 from '../../utils/carrosel/a3.png';
import './DocumentacaoAgentes.css';

const carouselImages = [carouselImage1, carouselImage2, carouselImage3];

const Carousel = () => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % carouselImages.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const nextImage = () => {
        setCurrentImage((prevImage) => (prevImage + 1) % carouselImages.length);
    };

    const prevImage = () => {
        setCurrentImage((prevImage) => (prevImage - 1 + carouselImages.length) % carouselImages.length);
    };

    return (
        <div className="carousel">
            <button className="carousel-button prev" onClick={prevImage} aria-label="Previous image">
                <FaChevronLeft />
            </button>
            <img src={carouselImages[currentImage]} alt={`Carousel image ${currentImage + 1}`} />
            <button className="carousel-button next" onClick={nextImage} aria-label="Next image">
                <FaChevronRight />
            </button>
        </div>
    );
};

const categorias = [
    {
        nome: 'Desenvolvimento e Análise',
        agentes: [
            {
                nome: 'Agente Dev Fullstack',
                descricao: 'Desenvolvimento completo de aplicações web e mobile, integrando front-end e back-end.',
                icon: <FaLaptopCode />,
                habilidades: [
                    { nome: 'JavaScript', icon: <FaCode />, descricao: 'Linguagem de programação versátil para web' },
                    { nome: 'React', icon: <FaReact />, descricao: 'Biblioteca para construção de interfaces de usuário' },
                    { nome: 'Node.js', icon: <FaNodeJs />, descricao: 'Ambiente de execução JavaScript server-side' },
                    { nome: 'SQL', icon: <FaDatabase />, descricao: 'Linguagem para gerenciamento de bancos de dados relacionais' },
                    { nome: 'REST APIs', icon: <FaServer />, descricao: 'Arquitetura para sistemas distribuídos' },
                    { nome: 'Git', icon: <FaGitAlt />, descricao: 'Sistema de controle de versão distribuído' }
                ],
                videoUrl: 'https://www.youtube.com/embed/Y_bK8YHCc7I'
            },
            {
                nome: 'Agente Analista de Dados',
                descricao: 'Análise avançada de dados industriais para otimização de processos e tomada de decisões.',
                icon: <FaChartBar />,
                habilidades: [
                    { nome: 'Python', icon: <FaPython />, descricao: 'Linguagem de programação para análise de dados' },
                    { nome: 'R', icon: <FaChartLine />, descricao: 'Linguagem para computação estatística' },
                    { nome: 'Machine Learning', icon: <FaRobot />, descricao: 'Algoritmos de aprendizado de máquina' },
                    { nome: 'Visualização de Dados', icon: <FaChartBar />, descricao: 'Técnicas para representação visual de dados' },
                    { nome: 'Estatística', icon: <FaChartPie />, descricao: 'Análise e interpretação de dados' },
                    { nome: 'Big Data', icon: <FaDatabase />, descricao: 'Processamento de grandes volumes de dados' }
                ],
                videoUrl: 'https://www.youtube.com/embed/Y_bK8YHCc7I'
            },
            {
                nome: 'Agente Analista de Dados BD',
                descricao: 'Especialista em análise e otimização de bancos de dados para melhorar performance e eficiência.',
                icon: <FaDatabase />,
                habilidades: [
                    { nome: 'SQL', icon: <FaDatabase />, descricao: 'Linguagem para gerenciamento de bancos de dados relacionais' },
                    { nome: 'NoSQL', icon: <FaDatabase />, descricao: 'Bancos de dados não relacionais' },
                    { nome: 'Data Warehousing', icon: <FaWarehouse />, descricao: 'Armazenamento centralizado de dados' },
                    { nome: 'ETL', icon: <FaExchangeAlt />, descricao: 'Extração, Transformação e Carga de dados' },
                    { nome: 'Modelagem de Dados', icon: <FaProjectDiagram />, descricao: 'Criação de modelos de dados eficientes' },
                    { nome: 'Otimização de Queries', icon: <FaDatabase />, descricao: 'Melhoria de desempenho de consultas' }
                ],
                videoUrl: 'https://www.youtube.com/embed/Y_bK8YHCc7I'
            },
        ]
    },
    {
        nome: 'SIGMA/PCM',
        agentes: [
            {
                nome: 'Agente Engenheiro PCM',
                descricao: 'Planejamento e controle de manutenção para maximizar a eficiência operacional e minimizar downtime.',
                icon: <FaCogs />,
                habilidades: [
                    { nome: 'Planejamento de Manutenção', icon: <FaClipboardCheck />, descricao: 'Elaboração de planos de manutenção preventiva' },
                    { nome: 'Gestão de Ativos', icon: <FaTools />, descricao: 'Gerenciamento eficiente de equipamentos e recursos' },
                    { nome: 'Análise de Falhas', icon: <FaChartLine />, descricao: 'Identificação e prevenção de falhas em equipamentos' },
                    { nome: 'KPIs de Manutenção', icon: <FaChartBar />, descricao: 'Métricas para avaliação de desempenho da manutenção' },
                    { nome: 'SAP PM', icon: <FaCogs />, descricao: 'Módulo de Manutenção de Planta do SAP' },
                    { nome: 'MS Project', icon: <FaProjectDiagram />, descricao: 'Software de gerenciamento de projetos' }
                ],
                videoUrl: 'https://www.youtube.com/embed/Y_bK8YHCc7I'
            },
            {
                nome: 'Agente IAN',
                descricao: 'Inteligência artificial avançada para manutenção preditiva e otimização de processos industriais.',
                icon: <FaBrain />,
                habilidades: [
                    { nome: 'Machine Learning', icon: <FaRobot />, descricao: 'Algoritmos de aprendizado de máquina' },
                    { nome: 'Processamento de Sinais', icon: <FaChartLine />, descricao: 'Análise e interpretação de sinais de sensores' },
                    { nome: 'IoT', icon: <FaProjectDiagram />, descricao: 'Internet das Coisas para coleta de dados' },
                    { nome: 'Análise Preditiva', icon: <FaChartLine />, descricao: 'Previsão de falhas e manutenção necessária' },
                    { nome: 'Deep Learning', icon: <FaBrain />, descricao: 'Redes neurais profundas para análise complexa' },
                    { nome: 'Automação Industrial', icon: <FaIndustry />, descricao: 'Implementação de processos automatizados' }
                ],
                videoUrl: 'https://www.youtube.com/embed/Y_bK8YHCc7I'
            },
            {
                nome: 'Agente Planejador PCM',
                descricao: 'Planejamento estratégico de manutenção para garantir a confiabilidade e disponibilidade dos equipamentos.',
                icon: <FaBriefcase />,
                habilidades: [
                    { nome: 'Cronograma de Manutenção', icon: <FaClock />, descricao: 'Elaboração de cronogramas eficientes' },
                    { nome: 'Gestão de Recursos', icon: <FaTools />, descricao: 'Alocação otimizada de pessoal e materiais' },
                    { nome: 'Análise de Criticidade', icon: <FaChartBar />, descricao: 'Identificação de equipamentos críticos' },
                    { nome: 'Orçamento de Manutenção', icon: <FaMoneyBillWave />, descricao: 'Planejamento e controle de custos' },
                    { nome: 'CMMS', icon: <FaDatabase />, descricao: 'Sistema computadorizado de gestão da manutenção' },
                    { nome: 'Lean Maintenance', icon: <FaClipboardList />, descricao: 'Aplicação de princípios lean na manutenção' }
                ],
                videoUrl: 'https://www.youtube.com/embed/Y_bK8YHCc7I'
            },
        ]
    }
];

const AgentCard = ({ agente, onClick }) => (
    <div className="agent-card" onClick={() => onClick(agente)}>
        <div className="agent-card-content">
            <div className="agent-image-wrapper">
                <div className="agent-image">
                    <img src={defaultAgentImage} alt={agente.nome} />
                </div>
                <div className="agent-icon-badge">
                    {agente.icon}
                </div>
            </div>
            <div className="agent-info">
                <h3>{agente.nome}</h3>
                <p>{agente.descricao}</p>
            </div>
            <button className="more-info-btn">
                Mais informações
            </button>
        </div>
    </div>
);

const AgentDetails = ({ agente, onClose }) => (
    <div className="agent-details">
        <button className="close-details" onClick={onClose} aria-label="Fechar detalhes">
            <FaTimes />
        </button>
        <div className="agent-details-content">
            <div className="agent-image">
                <img src={defaultAgentImage} alt={agente.nome} />
            </div>
            <h2>{agente.nome}</h2>
            <div className="agent-details-icon">{agente.icon}</div>

            <div className="video-container">
                <iframe
                    width="560"
                    height="315"
                    src={agente.videoUrl}
                    title={`Vídeo de ${agente.nome}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            <h3 className="description-title">Descrição</h3>
            <p className="agent-description">{agente.descricao}</p>

            <h3>Habilidades</h3>
            <ul className="agent-skills">
                {agente.habilidades.map((habilidade, index) => (
                    <li key={index}>
                        <div className="skill-icon">{habilidade.icon}</div>
                        <div className="skill-content">
                            <span className="skill-name">{habilidade.nome}</span>
                            <span className="skill-description">{habilidade.descricao}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

const CategorySection = ({ categoria, onAgentClick }) => {
    const [showAll, setShowAll] = useState(false);

    const displayedAgents = showAll ? categoria.agentes : categoria.agentes.slice(0, 2);

    return (
        <div className="category-section">
            <div className="category-header">
                <h2>{categoria.nome}</h2>
                {categoria.agentes.length > 2 && (
                    <button className="show-more-btn" onClick={() => setShowAll(!showAll)}>
                        {showAll ? 'Ver Menos' : 'Ver Mais'}
                    </button>
                )}
            </div>
            <div className="agents-grid">
                {displayedAgents.map((agente, index) => (
                    <AgentCard key={index} agente={agente} onClick={onAgentClick} />
                ))}
            </div>
        </div>
    );
};

export default function AgentsPage() {
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [darkMode, setDarkMode] = useState(false);

    const handleAgentClick = (agente) => {
        setSelectedAgent(agente);
    };

    const closeAgentDetails = () => {
        setSelectedAgent(null);
    };

    const toggleTheme = () => {
        setDarkMode(!darkMode);
        document.body.classList.toggle('dark-mode');
    };

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [darkMode]);

    return (
        <>
            <Navbar sidebar="sim" options={[]} />
            <div className="agents-page">
                <Carousel />
                <div className="page-header">
                    <h1>Agentes MIAGS</h1>
                    <p className="page-description">
                        Conheça nossa equipe de agentes especializados em diferentes áreas de atuação
                    </p>
                </div>
                <main>
                    <div className="categories-container">
                        {categorias.map((categoria, index) => (
                            <CategorySection
                                key={index}
                                categoria={categoria}
                                onAgentClick={handleAgentClick}
                            />
                        ))}
                    </div>
                </main>
                {selectedAgent && (
                    <div className="popup-overlay" onClick={closeAgentDetails}>
                        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                            <AgentDetails agente={selectedAgent} onClose={closeAgentDetails} />
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
}