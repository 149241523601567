import React from 'react';
import { Helmet } from 'react-helmet'; // Certifique-se de que esta importação está correta
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './PcmNovo.css'; // Mantém o mesmo estilo da PcmPage
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat';
import agentImage from '../../utils/agent.png';

function PcmNovo() {
    return (
        <div className="page-container">
            <Helmet>
                <title>Agente PCM - Melhorado</title>
                <link rel="icon" href="/Frontend/src/imgs/favicon.ico" /> {/* Caminho do favicon */}
            </Helmet>
            <Navbar />
            <div className="content-wrap">
                <main className="chat-container">
                    <AgentMenu />
                    <div className="chat-window-container">
                        <Chat
                            name="Agente PCM"
                            description="Especialista Avançado em Planejamento e Controle de Manutenção"
                            status="Online"
                            avatar={agentImage}
                            endpoint="https://apirussel.industria-ian.com/pcm_melhorado/conversa" // Nova rota do endpoint
                            payloadBuilder={(userInput) => ({
                                mensagem: userInput, // JSON no formato específico para PCM Melhorado
                            })}
                            responseKey="resposta" // Chave da resposta no JSON
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default PcmNovo;
