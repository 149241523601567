import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import {
  FaLaptopCode,
  FaChartBar,
  FaToolbox,
  FaDatabase,
  FaBrain,
  FaBriefcase,
  FaCogs,
  FaHardHat,
  FaWrench,
  FaClipboardCheck,
  FaUserShield,
  FaUserTie,
  FaClipboardList,
  FaUserCog,
  FaInfoCircle
} from 'react-icons/fa';
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

export default function AgentMenu({ darkMode, onAgentSelect }) {
  const navigate = useNavigate();

  const agents = [
    // {
    //   name: 'Agente Dev Fullstack',
    //   description: 'Especialista em desenvolvimento web full stack',
    //   icon: FaLaptopCode,
    //   category: 'Programação',
    //   skills: ['React', 'Node.js', 'MongoDB', 'Express'],
    //   route: 'chat'
    // },
    // {
    //   name: 'Agente Analista de Dados',
    //   description: 'Especialista em análise e visualização de dados',
    //   icon: FaChartBar,
    //   category: 'PCM',
    //   skills: ['Python', 'R', 'Tableau', 'Machine Learning'],
    //   route: 'analista-dados'
    // },
    // {
    //   name: 'Agente Analista de Dados BD',
    //   description: 'Especialista em bancos de dados e análise',
    //   icon: FaDatabase,
    //   category: 'PCM',
    //   skills: ['SQL', 'NoSQL', 'Data Warehousing', 'ETL'],
    //   route: 'analista-dados-bd'
    // },
    // {
    //   name: 'Agente Engenheiro PCM',
    //   description: 'Especialista em planejamento e controle de manutenção',
    //   icon: FaToolbox,
    //   category: 'PCM',
    //   skills: ['Gestão de Manutenção', 'Planejamento', 'KPIs', 'SAP/ERP'],
    //   route: 'pcm'
    // },
    {
      name: 'Agente Engenheiro PCM',
      description: 'Especialista em planejamento e controle de manutenção',
      icon: FaToolbox,
      category: 'PCM',
      skills: ['Gestão de Manutenção', 'Planejamento', 'KPIs', 'SAP/ERP'],
      route: 'pcm-novo'
    },
    {
      name: 'Agente IAN',
      description: 'Inteligência Artificial para Negócios',
      icon: FaBrain,
      category: 'PCM',
      skills: ['Machine Learning', 'NLP', 'Predictive Analytics', 'AI Strategy'],
      route: 'ian'
    },
    {
      name: 'Agente Planejador PCM',
      description: 'Especialista em planejamento de manutenção',
      icon: FaBriefcase,
      category: 'PCM',
      skills: ['Cronogramas', 'Orçamentos', 'Gestão de Recursos', 'MS Project'],
      route: 'planejador-pcm'
    },
    {
      name: 'Agente Engenheiro Confiabilidade',
      description: 'Especialista em engenharia de confiabilidade',
      icon: FaCogs,
      category: 'PCM',
      skills: ['RCM', 'FMEA', 'Análise de Falhas', 'Reliability Engineering'],
      route: 'engenheiro-confiabilidade'
    },
    {
      name: 'Agente Engenheiro de Produção',
      description: 'Especialista em engenharia de produção',
      icon: FaHardHat,
      category: 'PCM',
      skills: ['Lean Manufacturing', 'Six Sigma', 'Gestão de Processos', 'Otimização'],
      route: 'engenheiro-producao'
    },
    {
      name: 'Agente Técnico de Manutenção',
      description: 'Especialista em manutenção técnica',
      icon: FaWrench,
      category: 'PCM',
      skills: ['Manutenção Preventiva', 'Manutenção Corretiva', 'Diagnóstico de Falhas', 'Ferramentas Especializadas'],
      route: 'tecnico-manutencao'
    },
    {
      name: 'Agente Engenheiro de Manutenção',
      description: 'Especialista em engenharia de manutenção',
      icon: FaClipboardCheck,
      category: 'PCM',
      skills: ['Gestão de Ativos', 'Manutenção Preditiva', 'Análise de Vibração', 'Termografia'],
      route: 'engenheiro-manutencao'
    },
    {
      name: 'Agente Analista Confiabilidade',
      description: 'Especialista em análise de confiabilidade',
      icon: FaUserShield,
      category: 'PCM',
      skills: ['Análise de Dados de Falha', 'Modelagem de Confiabilidade', 'Weibull Analysis', 'Root Cause Analysis'],
      route: 'analista-confiabilidade'
    },
    {
      name: 'Agente Gestor Manutenção',
      description: 'Especialista em gestão de manutenção',
      icon: FaUserTie,
      category: 'PCM',
      skills: ['Liderança de Equipe', 'Gestão de Projetos', 'Orçamento de Manutenção', 'KPIs de Manutenção'],
      route: 'gestor-manutencao'
    },
    {
      name: 'Agente Analista PCM',
      description: 'Especialista em análise de PCM',
      icon: FaClipboardList,
      category: 'PCM',
      skills: ['Análise de Dados de Manutenção', 'Indicadores de Desempenho', 'Relatórios Gerenciais', 'Otimização de Processos'],
      route: 'analista-pcm'
    },
    {
      name: 'Agente Supervisor de Manutenção',
      description: 'Especialista em supervisão de manutenção',
      icon: FaUserCog,
      category: 'PCM',
      skills: ['Coordenação de Equipes', 'Planejamento de Turnos', 'Gestão de Segurança', 'Controle de Qualidade'],
      route: 'supervisor-manutencao'
    }
  ];

  const categories = ['PCM'];
  <AgentMenu darkMode={true} onAgentSelect={(agent) => console.log(agent)} />
  const handleAgentClick = (agent) => {
    if (onAgentSelect) {
      onAgentSelect(agent);
    }
    navigate(`/${agent.route}`);
  };

  return (
    <div className={`agent-menu ${darkMode ? 'dark' : ''}`}>
      <div className="menu-header">
        <h2>Menu de Agentes</h2>
        <p>Selecione um agente para interagir</p>
      </div>

      <div className="menu-content">
        {categories.map((category) => (
          <div key={category} className="menu-category">
            <div className="category-title">{category}</div>
            <div className="agent-list">
              {agents
                .filter((agent) => agent.category === category)
                .map((agent, index) => (
                  <div key={index} className="agent-item" onClick={() => handleAgentClick(agent)}>
                    <NavLink to={`/${agent.route}`} className="agent-item-content">
                      <div className="agent-icon-wrapper">
                        <agent.icon />
                      </div>
                      <span className="agent-name">{agent.name}</span>
                    </NavLink>
                    <Popover>
                      <PopoverTrigger asChild>
                        <button className="info-button" onClick={(e) => e.stopPropagation()} aria-label="Mais informações">
                          <FaInfoCircle />
                        </button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <div className="agent-info">
                          <h3>{agent.name}</h3>
                          <p>{agent.description}</p>
                          <h4>Habilidades:</h4>
                          <ul>
                            {agent.skills.map((skill, idx) => (
                              <li key={idx}>{skill}</li>
                            ))}
                          </ul>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .agent-menu {
          background: ${darkMode ? '#2c2c2c' : '#ffffff'};
          border-radius: 12px;
          padding: 1rem;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          width: 285px;
          transition: all 0.3s ease;
          display: flex;
          flex-direction: column;
          height: 80vh;
        }

        .menu-header {
          margin-bottom: 1rem;
          text-align: center;
        }

        .menu-header h2 {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${darkMode ? '#ffffff' : '#333333'};
          margin: 0;
        }

        .menu-header p {
          font-size: 0.875rem;
          color: ${darkMode ? '#a0a0a0' : '#666666'};
          margin: 0.5rem 0 0;
        }

        .menu-content {
          flex-grow: 1;
          overflow-y: auto;
          padding-right: 0.5rem;
        }

        .menu-category {
          margin-bottom: 1rem;
        }

        .category-title {
          font-size: 0.875rem;
          font-weight: 600;
          color: ${darkMode ? '#ffffff' : '#333333'};
          padding: 0.5rem 0;
          border-bottom: 1px solid ${darkMode ? '#404040' : '#e0e0e0'};
          margin-bottom: 0.5rem;
          position: sticky;
          top: 0;
          background: ${darkMode ? '#2c2c2c' : '#ffffff'};
          z-index: 1;
        }

        .agent-list {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .agent-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.75rem;
          border-radius: 8px;
          background: ${darkMode ? '#333333' : '#f8f9fa'};
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .agent-item:hover {
          background: ${darkMode ? '#404040' : '#f0f0f0'};
        }

        .agent-item-content {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          text-decoration: none;
          color: inherit;
          flex-grow: 1;
        }

        .agent-icon-wrapper {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: ${darkMode ? '#2c2c2c' : '#e8e8e8'};
          color: #007bff;
        }

        .agent-name {
          font-size: 0.875rem;
          color: ${darkMode ? '#ffffff' : '#333333'};
        }

        .info-button {
          background: transparent;
          border: none;
          color: ${darkMode ? '#a0a0a0' : '#666666'};
          cursor: pointer;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.2s ease;
        }

        .info-button:hover {
          color: #007bff;
        }

        .agent-info h3 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
          color: ${darkMode ? '#ffffff' : '#333333'};
        }

        .agent-info p {
          font-size: 0.875rem;
          margin-bottom: 0.75rem;
          color: ${darkMode ? '#a0a0a0' : '#666666'};
        }

        .agent-info h4 {
          font-size: 0.875rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
          color: ${darkMode ? '#ffffff' : '#333333'};
        }

        .agent-info ul {
          list-style-type: disc;
          padding-left: 1.25rem;
          margin: 0;
        }

        .agent-info li {
          font-size: 0.8125rem;
          color: ${darkMode ? '#a0a0a0' : '#666666'};
          margin-bottom: 0.25rem;
        }

        @media (max-width: 768px) {
          .agent-menu {
            width: 100%;
            margin-bottom: 1rem;
            height: auto;
            max-height: 300px;
          }

          .menu-content {
            max-height: none;
          }

          .agent-list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 0.5rem;
          }

          .agent-item {
            margin: 0;
          }
        }

        @media (max-width: 480px) {
          .agent-menu {
            padding: 0.75rem;
            max-height: 250px;
          }

          .menu-header h2 {
            font-size: 1.125rem;
          }

          .menu-header p {
            font-size: 0.75rem;
          }

          .agent-list {
            grid-template-columns: 1fr;
          }

          .agent-item {
            padding: 0.5rem;
          }

          .agent-icon-wrapper {
            width: 28px;
            height: 28px;
          }

          .agent-name {
            font-size: 0.8125rem;
          }
        }
      `}</style>
    </div>
  );
}