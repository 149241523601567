import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './AnaliseDados.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Importa o componente Chat
import UrlMenu from '../../components/UrlMenu/UrlMenu'; // Importa o componente UrlMenu
import agentImage from '../../utils/agent.png'; // Imagem do agente

function AnaliseDados() {
  const [urls, setUrls] = useState([]); // URLs adicionados pelo usuário

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-wrap">
        <main className="chat-container">
          <AgentMenu />
          <div className="chat-window-container">
            {/* Componente Chat configurado para AnaliseDados */}
            <Chat
              name="Agente Analise de Dados"
              description="Especialista em análise de dados e relatórios"
              status="Online"
              avatar={agentImage}
              endpoint="https://apirussel.industria-ian.com/analisar-dados"
              payloadBuilder={(userInput) => ({
                descricao: userInput,
                api_urls: urls, // Inclui os URLs adicionados pelo usuário no payload
              })}
              responseKey="analise" // Define a chave usada para acessar a resposta no JSON
            />
            {/* Componente UrlMenu */}
            <UrlMenu urls={urls} setUrls={setUrls} />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default AnaliseDados;
