import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './SupervisorManutencao.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Importa o componente Chat
import agentImage from '../../utils/agent.png'; // Imagem do agente

function SupervisorManutencao() {
    return (
        <div className="page-container">
            <Navbar />
            <div className="content-wrap">
                <main className="chat-container">
                    <AgentMenu />
                    <div className="chat-window-container">
                        {/* Componente Chat configurado para Supervisor de Manutenção */}
                        <Chat
                            name="Agente Supervisor de Manutenção"
                            description="Especialista em supervisão de manutenção e gestão de equipes"
                            status="Online"
                            avatar={agentImage}
                            endpoint="https://apirussel.industria-ian.com/agente_supervisor_manutencao/conversa"
                            payloadBuilder={(userInput) => ({
                                mensagem: userInput, // JSON no formato esperado pela API
                            })}
                            responseKey="resposta" // Define a chave usada para acessar a resposta no JSON
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default SupervisorManutencao;
