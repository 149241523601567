import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaPaperPlane, FaPaperclip, FaCircle } from 'react-icons/fa';
import Plot from 'react-plotly.js';
import { useSearchParams } from 'react-router-dom';
import './Chat.css';
import userIcon from '../../utils/user_icon.png';

export default function Chat({
    name,
    description,
    status,
    avatar,
    endpoint,
    payloadBuilder,
    responseKey,
}) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [userAvatar, setUserAvatar] = useState(userIcon); // Define imagem padrão do usuário
    const [searchParams] = useSearchParams(); // Para obter os parâmetros da URL
    const [api, setApi] = useState('');
    const [dbid, setDbid] = useState('');

    useEffect(() => {
        // Obtém os valores de `api` e `dbid` da URL
        const apiParam = searchParams.get('api');
        const dbidParam = searchParams.get('dbid');
        setApi(apiParam || 'http://demo.universosigma.com.br/api'); // Valor padrão
        setDbid(dbidParam || '30'); // Valor padrão

        const storedImagePath = localStorage.getItem('userImagePath'); // Caminho da imagem salvo no login
        console.log('Caminho da imagem:', storedImagePath);

        if (storedImagePath) {
            setUserAvatar(storedImagePath); // Define a URL da imagem diretamente
        } else {
            console.log('Nenhuma imagem de usuário encontrada. Usando imagem padrão.');
            setUserAvatar(userIcon);
        }
    }, [searchParams]);

    const handleSendMessage = async () => {
        if (inputValue.trim() === '') return;

        const userMessage = { sender: 'user', text: inputValue };
        setMessages((prev) => [...prev, userMessage]);
        setInputValue('');
        setIsTyping(true);

        try {
            const payload = {
                ...payloadBuilder(inputValue),
                api, // Adiciona `api` ao payload
                dbid, // Adiciona `dbid` ao payload
            };

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Erro na comunicação: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            const serverResponse = data[responseKey];
            let responseText = '';
            let chartData = null;
            let imageUrl = null;

            // Processar gráfico se existir
            if (data.grafico) {
                try {
                    chartData = JSON.parse(data.grafico);
                } catch (error) {
                    console.error('Erro ao processar os dados do gráfico:', error);
                }
            }

            // Processar imagem se existir
            if (data.imagem) {
                imageUrl = data.imagem;
            }

            responseText = serverResponse || 'Resposta recebida.';

            const botMessage = {
                sender: 'bot',
                text: responseText,
                isChart: !!chartData,
                chartData: chartData,
                isImage: !!imageUrl,
                imageUrl: imageUrl,
            };

            setMessages((prev) => [...prev, botMessage]);
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
            setMessages((prev) => [
                ...prev,
                { sender: 'bot', text: 'Erro ao processar a mensagem. Tente novamente.' },
            ]);
        } finally {
            setIsTyping(false);
        }
    };

    const renderMessage = (msg, index) => {
        const hasText = msg.text && msg.text.trim() !== '';
        const hasChart = msg.isChart && msg.chartData;
        const hasImage = msg.isImage && msg.imageUrl;

        return (
            <div key={index} className={`message ${msg.sender}`}>
                <div className="message-icon">
                    <img
                        src={msg.sender === 'user' ? userAvatar : avatar}
                        alt={msg.sender === 'user' ? 'Usuário' : 'Agente'}
                    />
                </div>
                <div className="message-content">
                    {hasText && <p>{msg.text}</p>}
                    {hasChart && (
                        <div className="chart-container" style={{ marginTop: '10px' }}>
                            <Plot
                                data={msg.chartData.data}
                                layout={msg.chartData.layout}
                                style={{ width: '100%', height: '300px' }}
                            />
                        </div>
                    )}
                    {hasImage && (
                        <div className="image-container" style={{ marginTop: '10px' }}>
                            <img src={msg.imageUrl} alt="Imagem gerada" style={{ maxWidth: '100%' }} />
                        </div>
                    )}
                    {!hasText && !hasChart && !hasImage && (
                        <p>Erro ao processar a mensagem. Tente novamente.</p>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="chat-component">
            <div className="chat-header">
                <div className="agent-info">
                    <div className="agent-avatar">
                        <img src={avatar} alt={name} />
                    </div>
                    <div className="agent-details">
                        <h2>{name}</h2>
                        <p>{description}</p>
                    </div>
                </div>
                <div className={`agent-status ${status.toLowerCase()}`}>
                    <FaCircle className="status-icon" />
                    <span>{status}</span>
                </div>
            </div>
            <div className="chat-messages">
                {messages.map(renderMessage)}
                {isTyping && (
                    <div className="message bot">
                        <div className="message-icon">
                            <img src={avatar} alt="Agente" />
                        </div>
                        <div className="message-content">
                            <p className="typing">
                                Analisando dados<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Digite sua mensagem"
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                />
                <button className="attachment-button">
                    <FaPaperclip />
                </button>
                <button className="send-button" onClick={handleSendMessage}>
                    <FaPaperPlane />
                    <span>Enviar</span>
                </button>
            </div>
        </div>
    );
}

Chat.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    payloadBuilder: PropTypes.func.isRequired,
    responseKey: PropTypes.string.isRequired,
};
