import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaComments, FaBook, FaSignInAlt, FaMoon, FaSun, FaBars, FaTimes } from 'react-icons/fa';
import { GiBookmarklet } from 'react-icons/gi';
import './Navbar.css';
import logo from '/home/Miags/Frontend/src/imgs/logo.png'; // Importando o logo diretamente

function Navbar() {
  const [darkMode, setDarkMode] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navbarRef = useRef(null);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav ref={navbarRef} className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <div className="navbar-left">
          <Link to="/" className="brand">
            <img src={logo} alt="Logo" className="brand-logo" />
            MIAGS
          </Link>
        </div>

        <div className={`navbar-center ${mobileMenuOpen ? 'active' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleMobileMenu}><FaHome /> Início</Link></li>
            <li><Link to="/chat" onClick={toggleMobileMenu}><FaComments /> Chat</Link></li>
            <li><Link to="/custom" onClick={toggleMobileMenu}><FaBook /> Custom</Link></li>
            <li><Link to="/documentacao" onClick={toggleMobileMenu}><GiBookmarklet /> Documentação</Link></li>
          </ul>
        </div>

        <div className="navbar-right">
          <Link to="/login" className="login-link">
            <FaSignInAlt /> Login
          </Link>
          <button onClick={toggleTheme} className="theme-toggle">
            {darkMode ? <FaSun /> : <FaMoon />}
          </button>
          <button className="mobile-menu-toggle" onClick={toggleMobileMenu} aria-label="Toggle mobile menu">
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
