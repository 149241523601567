import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthForm.css';

function AuthForm() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [dbid, setDbid] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const API_URL = 'https://apirussel.industria-ian.com';
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem('authToken'));
    const storedName = localStorage.getItem('userName');
    const storedDbid = localStorage.getItem('userDbid');

    if (storedToken && storedToken.token && !isTokenExpired(storedToken.expiration)) {
      setNomeCompleto(storedName || '');
      setDbid(storedDbid || '');
      setIsLoggedIn(true);
    }
  }, []);

  const isTokenExpired = (expiration) => {
    const now = new Date();
    const expirationDate = new Date(expiration);
    return now > expirationDate;
  };

  const saveToLocalStorage = (token, name, dbid) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    localStorage.setItem(
      'authToken',
      JSON.stringify({ token, expiration: expirationDate.toISOString() })
    );
    localStorage.setItem('userName', name);
    localStorage.setItem('userDbid', dbid);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userDbid');
    setNomeCompleto('');
    setDbid('');
    setIsLoggedIn(false);
    setSuccess('');
    setError('');
    navigate('/login');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !senha) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, senha }),
      });

      const data = await response.json();
      if (response.ok) {
        saveToLocalStorage(data.data.api, data.data.nome_completo, data.data.dbid);

        setNomeCompleto(data.data.nome_completo);
        setDbid(data.data.dbid);
        setIsLoggedIn(true);
        setSuccess('Login realizado com sucesso!');
        setError('');

        navigate(`/chat?api=${data.data.api}&dbid=${data.data.dbid}`);
      } else {
        setError(data.message || 'Erro ao realizar login.');
        setSuccess('');
      }
    } catch (err) {
      setError('Erro de comunicação com o servidor.');
      setSuccess('');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!nomeCompleto || !email || !senha || !confirmarSenha || !empresa || !dbid) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    if (senha !== confirmarSenha) {
      setError('As senhas não coincidem.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/usuarios`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          nome_completo: nomeCompleto,
          email,
          senha,
          empresa,
          dbid,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccess('Cadastro realizado com sucesso!');
        setError('');
        setIsRegistering(false);
      } else {
        setError(data.message || 'Erro ao realizar cadastro.');
        setSuccess('');
      }
    } catch (err) {
      setError('Erro de comunicação com o servidor.');
      setSuccess('');
    }
  };

  return (
    <div className="auth-container">
      {isLoggedIn ? (
        <div className="logged-in">
          <h1>Bem-vindo, {nomeCompleto}!</h1>
          <button onClick={handleLogout} className="button logout">
            Logout
          </button>
        </div>
      ) : isRegistering ? (
        <div className="form-container register-form">
          <h1>Cadastro</h1>
          <form onSubmit={handleRegister}>
            <div className="form-group">
              <input
                type="text"
                id="nomeCompleto"
                value={nomeCompleto}
                onChange={(e) => setNomeCompleto(e.target.value)}
                placeholder="Nome Completo"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                placeholder="Senha"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="confirmarSenha"
                value={confirmarSenha}
                onChange={(e) => setConfirmarSenha(e.target.value)}
                placeholder="Confirme a Senha"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="empresa"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                placeholder="Empresa"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="dbid"
                value={dbid}
                onChange={(e) => setDbid(e.target.value)}
                placeholder="DBID"
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <button type="submit" className="button">Cadastrar</button>
          </form>
          <p className="toggle-form">
            Já tem uma conta?{' '}
            <button onClick={() => setIsRegistering(false)} className="toggle-button">
              Faça login
            </button>
          </p>
        </div>
      ) : (
        <div className="form-container login-form">
          <h1>Login</h1>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                placeholder="Senha"
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <button type="submit" className="button">Entrar</button>
          </form>
          <p className="toggle-form">
            Não tem uma conta?{' '}
            <button onClick={() => setIsRegistering(true)} className="toggle-button">
              Cadastre-se
            </button>
          </p>
        </div>
      )}
    </div>
  );
}

export default AuthForm;

