// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f7fc;
}

.content-wrap {
  flex-grow: 1;
  padding: 20px;
  margin-top: 60px;
}

.chat-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.chat-window-container {
  display: flex;
  width: 100%;
  max-width: 1400px;
  gap: 20px;
}

.chat-window {
  flex: 2 1;
  height: 85vh;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AnaliseBd/AnaliseBd.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,yCAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".page-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  min-height: 100vh;\r\n  background-color: #f4f7fc;\r\n}\r\n\r\n.content-wrap {\r\n  flex-grow: 1;\r\n  padding: 20px;\r\n  margin-top: 60px;\r\n}\r\n\r\n.chat-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 20px;\r\n}\r\n\r\n.chat-window-container {\r\n  display: flex;\r\n  width: 100%;\r\n  max-width: 1400px;\r\n  gap: 20px;\r\n}\r\n\r\n.chat-window {\r\n  flex: 2;\r\n  height: 85vh;\r\n  background-color: #ffffff;\r\n  border-radius: 20px;\r\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
