import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './AnaliseBd.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Importa o componente Chat
import agentImage from '../../utils/agent.png'; // Imagem do agente

function AnaliseBd() {
  return (
    <div className="page-container">
      <Navbar />
      <div className="content-wrap">
        <main className="chat-container">
          <AgentMenu />
          <div className="chat-window-container">
            {/* Componente Chat configurado para AnaliseBd */}
            <Chat
              name="Agente Analista de Dados BD"
              description="Especialista em análise de dados e bancos"
              status="Online"
              avatar={agentImage}
              endpoint="https://apirussel.industria-ian.com/analise-banco"
              payloadBuilder={(userInput) => ({
                descricao: userInput, // JSON no formato esperado pela API
              })}
              responseKey="analise" // Define a chave usada para acessar a resposta no JSON
            />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default AnaliseBd;
