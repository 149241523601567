import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section footer-about">
          <h2>Rede Industrial</h2>
          <p>
            A Rede Industrial é referência em soluções tecnológicas e inovadoras
            para a indústria. Focamos em qualidade, segurança e eficiência.
          </p>
        </div>

        <div className="footer-section footer-links">
          <h3>Sobre Nós</h3>
          <ul>
            <li><a href="/sobre">Sobre nós</a></li>
            <li><a href="/servicos">Serviços</a></li>
            <li><a href="/contato">Contato</a></li>
            <li><a href="/privacidade">Política de Privacidade</a></li>
          </ul>
        </div>

        <div className="footer-section footer-socials">
          <h3>Redes Sociais</h3>
          <ul>
            <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /> Facebook</a></li>
            <li><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /> LinkedIn</a></li>
            <li><a href="https://www.instagram.com/redeindustrialoficial/" target="_blank" rel="noopener noreferrer"><FaInstagram /> Instagram</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Rede Industrial. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

export default Footer;