import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './IanPage.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Importa o componente Chat
import agentImage from '../../utils/agent.png'; // Imagem do agente

function IanPage() {
    const endpoint = 'https://api.industria-ian.com/ask'; // Endpoint do servidor

    return (
        <div className="page-container">
            <Navbar />
            <div className="content-wrap">
                <main className="chat-container">
                    <AgentMenu />
                    <div className="chat-window-container">
                        {/* Configura o componente Chat com informações específicas */}
                        <Chat
                            name="Agente Ian"
                            description="Especialista em perguntas e respostas"
                            status="Online"
                            avatar={agentImage}
                            endpoint="https://api.industria-ian.com/ask"
                            payloadBuilder={(userInput) => ({
                                question: userInput,
                                apiUrl: "http://demo.universosigma.com.br/api/",
                                dbid: 30,
                            })}
                            responseKey="answer" // Chave da resposta no JSON
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default IanPage;
