import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './UrlMenu.css';
import { FaTrashAlt } from 'react-icons/fa'; // Ícone de remover

function UrlMenu({ urls, setUrls }) {
    const [newUrl, setNewUrl] = useState('');

    const handleAddUrl = () => {
        if (newUrl.trim()) {
            setUrls((prevUrls) => [...prevUrls, newUrl]);
            setNewUrl('');
        }
    };

    const handleRemoveUrl = (indexToRemove) => {
        setUrls((prevUrls) => prevUrls.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div className="url-menu">
            <h3>Endpoints</h3>
            <ul className="url-list">
                {urls.map((url, index) => (
                    <li key={index} className="url-item">
                        <span>{url}</span>
                        <button
                            className="url-remove-button"
                            onClick={() => handleRemoveUrl(index)}
                            title="Remover endpoint"
                        >
                            <FaTrashAlt />
                        </button>
                    </li>
                ))}
            </ul>
            <div className="url-input-container">
                <input
                    type="text"
                    value={newUrl}
                    onChange={(e) => setNewUrl(e.target.value)}
                    className="url-input"
                    placeholder="Adicionar novo endpoint"
                />
                <button onClick={handleAddUrl} className="url-add-button">
                    Adicionar
                </button>
            </div>
        </div>
    );
}

UrlMenu.propTypes = {
    urls: PropTypes.arrayOf(PropTypes.string).isRequired,
    setUrls: PropTypes.func.isRequired,
};

export default UrlMenu;
