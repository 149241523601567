import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Importa o componente Chat
import agentImage from '../../utils/agent.png';
import './ChatPage.css';

export default function ChatPage() {
  const [darkMode, setDarkMode] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState({
    name: 'Agente Dev Fullstack',
    description: 'Especialista em desenvolvimento web full stack',
    status: 'Offline',
    icon: agentImage,
  });

  useEffect(() => {
    const bodyElement = document.body;
    if (darkMode) {
      bodyElement.classList.add('dark-mode');
    } else {
      bodyElement.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleAgentSelect = (agent) => {
    setSelectedAgent(agent);
  };

  return (
    <div className={`page-container ${darkMode ? 'dark-mode' : ''}`}>
      <Navbar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      <div className="content-wrap">
        <main className="chat-container">
          <AgentMenu darkMode={darkMode} onAgentSelect={handleAgentSelect} />
          {/* Substitui a implementação do chat pelo componente Chat */}
          <Chat
            name={selectedAgent.name}
            description={selectedAgent.description}
            status={selectedAgent.status}
            avatar={selectedAgent.icon}
          />
        </main>
      </div>
      <Footer />
    </div>
  );
}
