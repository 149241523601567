import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './PlanejadorPcmV3.css'; // Novo arquivo de estilos para PlanejadorPcmV3
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Componente de chat reutilizado
import agentImage from '../../utils/agent.png'; // Imagem do agente

function PlanejadorPcmV3() {
    return (
        <div className="page-container">
            <Navbar />
            <div className="content-wrap">
                <main className="chat-container">
                    <AgentMenu />
                    <div className="chat-window-container">
                        {/* Componente Chat configurado para Planejador PCM V3 */}
                        <Chat
                            name="Planejador PCM"
                            description="Especialista Avançado em Processos de Controle de Manutenção"
                            status="Online"
                            avatar={agentImage}
                            endpoint="https://apirussel.industria-ian.com/api/planejador_v3/conversa" // Atualizado para o novo endpoint
                            payloadBuilder={(userInput) => ({
                                mensagem: userInput, // JSON no formato esperado pelo novo endpoint
                            })}
                            responseKey="resposta" // Chave para acessar a resposta no JSON retornado
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default PlanejadorPcmV3;
