import React from 'react';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { category: 'A', amount: 262 },
  { category: 'B', amount: 173 },
  { category: 'C', amount: 209 },
  { category: 'D', amount: 168 },
  { category: 'E', amount: 289 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA4A44'];

const GraficPage = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Gráficos de Categorias</h1>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
        <div style={{ marginRight: '40px' }}>
          <h2>Gráfico de Barras</h2>
          <BarChart width={500} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" fill="#8884d8" />
          </BarChart>
        </div>
        <div>
          <h2>Gráfico de Pizza</h2>
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              dataKey="amount"
              label
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      </div>
      <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'left' }}>
        <h2>Insights e Padrões Identificados</h2>
        <ul>
          <li>A Categoria E tem a maior quantidade (289), enquanto a Categoria D tem a menor (168).</li>
          <li>As quantidades das categorias variam entre 168 e 289, indicando uma distribuição relativamente uniforme, mas com algumas diferenças notáveis.</li>
          <li>As categorias A e C têm quantidades próximas (262 e 209, respectivamente), sugerindo que podem ter um peso similar em análises futuras.</li>
        </ul>
        <h3>Sugestões de Melhorias</h3>
        <ul>
          <li>Normalização dos Dados: Se o conjunto de dados crescer, pode ser útil normalizar as quantidades para facilitar comparações em diferentes escalas.</li>
          <li>Análise de Tendências Temporais: Se forem coletadas mais dados ao longo do tempo, adicionar uma dimensão temporal pode ajudar a identificar tendências ou sazonalidades nas categorias.</li>
          <li>Segmentação Adicional: Considerar a segmentação das categorias em subcategorias, se aplicável, para uma análise mais detalhada.</li>
          <li>Incorporação de Outras Variáveis: Se possível, adicionar variáveis adicionais (como custos, receitas associadas, etc.) pode enriquecer a análise e fornecer insights mais profundos.</li>
        </ul>
      </div>
    </div>
  );
};

export default GraficPage;
