import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './AnalistaPcm.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat'; // Importa o componente Chat
import agentImage from '../../utils/agent.png'; // Imagem do agente

function AnalistaPcm() {
    return (
        <div className="page-container">
            <Navbar />
            <div className="content-wrap">
                <main className="chat-container">
                    <AgentMenu />
                    <div className="chat-window-container">
                        {/* Componente Chat configurado para Analista de PCM */}
                        <Chat
                            name="Agente Analista de PCM"
                            description="Especialista em planejamento e controle de manutenção"
                            status="Online"
                            avatar={agentImage}
                            endpoint="https://apirussel.industria-ian.com/agente_analista_pcm/conversa"
                            payloadBuilder={(userInput) => ({
                                mensagem: userInput, // JSON no formato esperado pela API
                            })}
                            responseKey="resposta" // Define a chave usada para acessar a resposta no JSON
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default AnalistaPcm;
