import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './AnaliseIndoor.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import agentImage from '../../utils/agent.png';
import userIcon from '../../utils/user_icon.png';

function AnaliseIndoor() {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [darkMode] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    // URL do endpoint para o backend
    const getEndpoint = () => {
        return 'https://apirussel.industria-ian.com/analise-indoor';
    };

    // Formata a resposta do bot
    const formatBotResponse = (data) => {
        if (data.analise) {
            return `Relatório de Análise:\n${data.analise}`;
        } else {
            return 'Ação concluída, mas nenhum resultado foi retornado.';
        }
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() === '') return;

        const userMessage = { sender: 'user', text: inputValue };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInputValue('');
        setIsTyping(true);

        try {
            const endpoint = getEndpoint();

            // Dados a serem enviados na requisição
            const requestData = {
                descricao: inputValue, // Captura a descrição a partir do input do usuário
                api_keys: ["users_by_company", "floor_plans", "sectors", "equipments"]
            };

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error(`Erro ao se comunicar com o servidor: ${response.statusText}`);
            }

            const data = await response.json();
            setIsTyping(false);

            // Formata a resposta do bot para exibir no chat
            const botMessage = { sender: 'bot', text: formatBotResponse(data) };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
            const errorMessage = { sender: 'bot', text: 'Erro ao enviar mensagem. Tente novamente mais tarde.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
            setIsTyping(false);
        }
    };

    return (
        <div className={`page-container ${darkMode ? 'dark-mode' : ''}`}>
            <Navbar />
            <div className="content-wrap">
                <main className="chat-container">
                    <AgentMenu />
                    <div className="chat-window-container">
                        <div className="chat-window">
                            <div className="chat-messages">
                                {messages.map((msg, index) => (
                                    <div key={index} className={`message ${msg.sender}`}>
                                        <div className="message-icon">
                                            {msg.sender === 'user' ? (
                                                <img src={userIcon} alt="Usuário" className="user-icon" />
                                            ) : (
                                                <img src={agentImage} alt="Agente" className="agent-icon" />
                                            )}
                                        </div>
                                        <div className="message-content">
                                            <p>{msg.text}</p>
                                        </div>
                                    </div>
                                ))}
                                {isTyping && (
                                    <div className="message bot">
                                        <div className="message-icon">
                                            <img src={agentImage} alt="Agente" className="agent-icon" />
                                        </div>
                                        <div className="message-content">
                                            <p className="typing">Pensando<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="chat-input">
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    className="input-field"
                                    placeholder="Digite sua mensagem ou descrição"
                                />
                                <button className="send-button" onClick={handleSendMessage}>
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default AnaliseIndoor;
