import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './PcmPage.css';
import AgentMenu from '../../components/AgentMenu/AgentMenu';
import Chat from '../../components/Chat/Chat';
import agentImage from '../../utils/agent.png';

function PcmPage() {
  return (
    <div className="page-container">
      <Navbar />
      <div className="content-wrap">
        <main className="chat-container">
          <AgentMenu />
          <div className="chat-window-container">
            <Chat
              name="Agente PCM"
              description="Especialista em Planejamento e Controle de Manutenção"
              status="Online"
              avatar={agentImage}
              endpoint="https://apirussel.industria-ian.com/pcm"
              payloadBuilder={(userInput) => ({
                mensagem: userInput, // JSON no formato específico para PCM
              })}
              responseKey="response" // Chave da resposta no JSON
            />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default PcmPage;
